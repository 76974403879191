"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSingerTaps = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var logos = __importStar(require("./logos"));
var types_1 = require("../types");
var helper_1 = require("./helper");
var documentation_1 = require("./documentation");
var commonParams_1 = require("./commonParams");
exports.allSingerTaps = [
    {
        pic: logos.tap_helpscout,
        displayName: "Helpscout",
        tap: "tap-helpscout",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-helpscout", {
            customConfig: [
                {
                    displayName: "Client ID",
                    id: "client_id",
                    type: types_1.oauthSecretType,
                    required: true,
                },
                {
                    displayName: "Client Secret",
                    id: "client_secret",
                    type: types_1.oauthSecretType,
                    required: true,
                },
                {
                    displayName: "Refresh Token",
                    id: "refresh_token",
                    type: types_1.stringType,
                    required: true,
                },
                {
                    displayName: "User Agent",
                    id: "user_agent",
                    defaultValue: "Jitsu.Cloud (https://jitsu.com)",
                    type: types_1.stringType,
                    required: true,
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00Z",
                    required: true,
                },
            ],
        }),
    },
    {
        pic: logos.tap_3plcentral,
        displayName: "3PL Central",
        tap: "tap-3plcentral",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_adroll,
        displayName: "AdRoll",
        tap: "tap-adroll",
        parameters: (0, helper_1.customParameters)("tap-adroll", {
            customConfig: [
                {
                    displayName: "Client ID",
                    id: "client_id",
                    type: types_1.oauthSecretType,
                    required: false,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["ID of the", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://apidocs.nextroll.com/guides/get-started.html" }, { children: "NextRoll API" })), " ", "application"] })),
                },
                {
                    displayName: "Client Secret",
                    id: "client_secret",
                    type: types_1.oauthSecretType,
                    required: false,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Secret of the API key generated using the", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://developers.nextroll.com/my-apps" }, { children: "NextRoll developer account" }))] })),
                },
                {
                    displayName: "Access Token",
                    id: "access_token",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Access Token.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://apidocs.nextroll.com/guides/oauth.html?highlight=access%20token#your-first-api-call" }, { children: "Learn how to generate it" })), " ", "using the NextRoll API"] })),
                },
                {
                    displayName: "Refresh Token",
                    id: "refresh_token",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Refresh Token.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://apidocs.nextroll.com/guides/oauth.html?highlight=access%20token#your-first-api-call" }, { children: "Learn how to generate it" })), " ", "using the NextRoll API"] })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    required: true,
                },
            ],
        }),
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_s3_csv,
        displayName: "Amazon S3 CSV",
        tap: "tap-s3-csv",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_amplitude,
        displayName: "Amplitude",
        tap: "tap-amplitude",
        stable: true,
        hasNativeEquivalent: true,
    },
    {
        pic: logos.tap_appsflyer,
        displayName: "AppsFlyer",
        tap: "tap-appsflyer",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_autopilot,
        displayName: "Autopilot",
        tap: "tap-autopilot",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_bigcommerce,
        displayName: "BigCommerce",
        tap: "tap-bigcommerce",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_bing_ads,
        displayName: "Bing Ads",
        tap: "tap-bing-ads",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_braintree,
        displayName: "Braintree",
        tap: "tap-braintree",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_bronto,
        displayName: "Bronto",
        tap: "tap-bronto",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_covid_19_public_data,
        displayName: "COVID-19 Public Data",
        tap: "tap-covid-19",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_campaign_manager,
        displayName: "Campaign Manager",
        tap: "tap-doubleclick-campaign-manager",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_campaign_monitor,
        displayName: "Campaign Monitor",
        tap: "tap-campaign-monitor",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_chargebee,
        displayName: "Chargebee",
        tap: "tap-chargebee",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_chargify,
        displayName: "Chargify",
        tap: "tap-chargify",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_close_io,
        displayName: "Close",
        tap: "tap-closeio",
        parameters: (0, helper_1.customParameters)("tap-closeio", {
            customConfig: [
                {
                    displayName: "API Token",
                    id: "api_key",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Close.com API Token. To obtain API Key login to your Close.com account, navigate to your Settings \u2013 API Keys. Generate a New API Key." })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2022-01-01T00:00:00Z",
                    required: true,
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "The date from which you'd like to replicate the data" }),
                },
            ],
        }),
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_clubspeed,
        displayName: "Club Speed",
        tap: "tap-clubspeed",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: null,
        displayName: "Codat",
        tap: "tap-codat",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_darksky,
        displayName: "Dark Sky",
        tap: "tap-darksky",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_deputy,
        displayName: "Deputy",
        tap: "tap-deputy",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_dynamodb,
        displayName: "Dynamo DB",
        tap: "tap-dynamodb",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_ebay,
        displayName: "Ebay",
        tap: "tap-ebay",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_eloqua,
        displayName: "Eloqua",
        tap: "tap-eloqua",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_exchange_rates_api,
        displayName: "Exchange Rates API",
        tap: "tap-exchangeratesapi",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_facebook_ads,
        displayName: "Facebook Ads",
        tap: "tap-facebook",
        stable: true,
        hasNativeEquivalent: true,
    },
    {
        pic: null,
        displayName: "Freshdesk",
        tap: "tap-freshdesk",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_frontapp,
        displayName: "Front",
        tap: "tap-frontapp",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_fullstory,
        displayName: "FullStory",
        tap: "tap-fullstory",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_github,
        displayName: "GitHub",
        tap: "tap-github",
        parameters: (0, helper_1.customParameters)("tap-github", {
            customConfig: [
                {
                    displayName: "Access Token",
                    id: "access_token",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Access Token. Generate it", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://github.com/settings/tokens" }, { children: "here" }))] })),
                },
                {
                    displayName: "Repository",
                    id: "repository",
                    type: types_1.stringType,
                    required: true,
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Repository as org/repo such as jitsucom/jitsu" }),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    required: true,
                },
            ],
        }),
        stable: true,
        hasNativeEquivalent: false,
        documentation: documentation_1.githubDocumentation,
    },
    {
        deprecated: true,
        pic: logos.tap_gitlab,
        displayName: "GitLab",
        tap: "tap-gitlab",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_google_ads,
        displayName: "Google Ads (AdWords)",
        tap: "tap-adwords",
        stable: false,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-adwords", {
            customConfig: [
                {
                    displayName: "Developer Token",
                    id: "developer_token",
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["API Developer token.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://services.google.com/fb/forms/newtoken/" }, { children: "Apply here" }))] })),
                },
                {
                    displayName: "OAuth Client ID",
                    id: "oauth_client_id",
                    required: true,
                },
                {
                    displayName: "OAuth Client Secret",
                    id: "oauth_client_secret",
                    required: true,
                },
                {
                    displayName: "Refresh Token",
                    id: "refresh_token",
                    required: true,
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    required: true,
                },
                {
                    displayName: "User Agent",
                    id: "user_agent",
                    type: types_1.stringType,
                    constant: "Jitsu Bot (https://jitsu.com)",
                },
            ],
        }),
    },
    {
        deprecated: true,
        pic: logos.tap_google_search_console,
        displayName: "Google Search Console",
        tap: "tap-google-search-console",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_google_sheets,
        displayName: "Google Sheets",
        tap: "tap-google-sheets",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-google-sheets", {
            customConfig: __spreadArray(__spreadArray([], (0, commonParams_1.googleAuthConfigParameters)({
                type: "type",
                clientId: "client_id",
                refreshToken: "refresh_token",
                clientSecret: "client_secret",
                disableServiceAccount: true,
                oauthSecretsRequired: false,
            }), true), [
                {
                    displayName: "Google Spreadsheet ID",
                    id: "spreadsheet_id",
                    type: types_1.stringType,
                    required: true,
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    required: true,
                },
                {
                    displayName: "User Agent",
                    id: "user_agent",
                    type: types_1.stringType,
                    constant: "Jitsu Bot (https://jitsu.com)",
                },
            ], false),
        }),
        documentation: documentation_1.googleSheetsDocumentation,
    },
    {
        deprecated: true,
        pic: logos.tap_harvest,
        displayName: "Harvest",
        tap: "tap-harvest",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_harvest_forecast,
        displayName: "Harvest Forecast",
        tap: "tap-harvest-forecast",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_heap,
        displayName: "Heap",
        tap: "tap-heap",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_hubspot,
        displayName: "HubSpot",
        tap: "tap-hubspot",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_ibm_db2,
        displayName: "IBM Db2",
        tap: "tap-db2",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_impact,
        displayName: "Impact",
        tap: "tap-impact",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_intercom,
        displayName: "Intercom",
        tap: "tap-intercom",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-intercom", {
            customConfig: [
                {
                    displayName: "API Access Token",
                    id: "access_token",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Intercom API Access Token.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://developers.intercom.com/building-apps/docs/authentication-types#section-access-tokens" }, { children: "Read how to get it" }))] })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    required: true,
                },
                {
                    displayName: "User Agent",
                    id: "user_agent",
                    type: types_1.stringType,
                    constant: "Jitsu Bot (https://jitsu.com)",
                },
            ],
        }),
        documentation: documentation_1.intercomDocumentation,
    },
    {
        pic: logos.tap_invoiced,
        displayName: "Invoiced",
        tap: "tap-invoiced",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_jira,
        displayName: "Jira",
        tap: "tap-jira",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_klaviyo,
        displayName: "Klaviyo",
        tap: "tap-klaviyo",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: false,
        pic: logos.tap_kustomer,
        displayName: "Kustomer",
        tap: "tap-kustomer",
        parameters: (0, helper_1.customParameters)("tap-kustomer", {
            customConfig: [
                {
                    displayName: "API Token",
                    id: "api_token",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Kustomer API Token. See", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://developer.kustomer.com/kustomer-api-docs/reference/authentication" }, { children: "the docs" })), " ", "on how to obtain this"] })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2022-01-01T00:00:00Z",
                    required: true,
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "The date from which you'd like to replicate the data" }),
                },
                {
                    displayName: "User Agent",
                    id: "user_agent",
                    type: types_1.stringType,
                    constant: "Jitsu Bot (https://jitsu.com)",
                },
            ],
        }),
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_lever,
        displayName: "Lever",
        tap: "tap-lever",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_linkedin_ads,
        displayName: "LinkedIn Ads",
        tap: "tap-linkedin-ads",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_listrak,
        displayName: "Listrak",
        tap: "tap-listrak",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_liveperson,
        displayName: "LivePerson",
        tap: "tap-liveperson",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_LookML,
        displayName: "LookML",
        tap: "tap-LookML",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_looker,
        displayName: "Looker",
        tap: "tap-looker",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_mailshake,
        displayName: "Mailshake",
        tap: "tap-mailshake",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_mambu,
        displayName: "Mambu",
        tap: "tap-mambu",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_marketo,
        displayName: "Marketo",
        tap: "tap-marketo",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_mixpanel,
        displayName: "Mixpanel",
        tap: "tap-mixpanel",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-mixpanel", {
            customConfig: [
                {
                    displayName: "API Secret",
                    id: "api_secret",
                    type: types_1.stringType,
                    required: true,
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "MixPanel API Secret. Obtain it in MixPanel UI project settings." }),
                },
                {
                    displayName: "Date Window Size",
                    id: "date_window_size",
                    type: types_1.intType,
                    required: true,
                    defaultValue: 30,
                    documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Number of days for date window looping through transactional endpoints with from_date and to_date. Clients with large volumes of events may want to decrease this to 14, 7, or even down to 1-2 days." })),
                },
                {
                    displayName: "Attribution Window",
                    id: "attribution_window",
                    type: types_1.intType,
                    required: true,
                    defaultValue: 5,
                    documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Latency minimum number of days to look-back to account for delays in attributing accurate results." })),
                },
                {
                    displayName: "Project Timezone",
                    id: "project_timezone",
                    type: types_1.stringType,
                    required: true,
                    defaultValue: "UTC",
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Time zone in which integer date times are stored. The project timezone may be found in the project settings in the Mixpanel console.", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://help.mixpanel.com/hc/en-us/articles/115004547203-Manage-Timezones-for-Projects-in-Mixpanel" }, { children: "More info about timezones" })), "."] })),
                },
                {
                    displayName: "Select properties by default",
                    id: "select_properties_by_default",
                    type: types_1.booleanType,
                    defaultValue: true,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Setting this config parameter to true ensures that new properties on events and engage records are captured. Otherwise new properties will be ignored." })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    required: true,
                },
                {
                    displayName: "User Agent",
                    id: "user_agent",
                    type: types_1.stringType,
                    constant: "Jitsu Bot (https://jitsu.com)",
                },
            ],
        }),
        documentation: documentation_1.mixpanelDocumentation,
    },
    {
        pic: logos.tap_onfleet,
        displayName: "Onfleet",
        tap: "tap-onfleet",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_oracle,
        displayName: "Oracle",
        tap: "tap-oracle",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_outbrain,
        displayName: "Outbrain",
        tap: "tap-outbrain",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_outreach,
        displayName: "Outreach",
        tap: "tap-outreach",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_pardot,
        displayName: "Pardot",
        tap: "tap-pardot",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_pendo,
        displayName: "Pendo",
        tap: "tap-pendo",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: null,
        displayName: "Pepperjam",
        tap: "tap-pepperjam",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_pipedrive,
        displayName: "Pipedrive",
        tap: "tap-pipedrive",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_platform_purple,
        displayName: "Platform Purple",
        tap: "tap-platformpurple",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_postgresql,
        displayName: "PostgreSQL",
        tap: "tap-postgres",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_mysql,
        displayName: "MySQL",
        tap: "tap-mysql",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-mysql", {
            customConfig: [
                {
                    id: "host",
                    displayName: "Host",
                    type: types_1.stringType,
                    required: true,
                },
                {
                    id: "port",
                    displayName: "Port",
                    type: types_1.intType,
                    required: true,
                    defaultValue: 3306,
                },
                {
                    id: "user",
                    displayName: "Username",
                    type: types_1.stringType,
                    required: true,
                },
                {
                    id: "password",
                    displayName: "Password",
                    type: types_1.passwordType,
                    required: true,
                },
            ],
        }),
        documentation: documentation_1.mySqlDocumentation,
    },
    {
        pic: logos.tap_quick_base,
        displayName: "Quick Base",
        tap: "tap-quickbase",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: null,
        displayName: "Recharge",
        tap: "tap-recharge",
        stable: false,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_recurly,
        displayName: "Recurly",
        tap: "tap-recurly",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_referral_saasquatch,
        displayName: "Referral SaaSquatch",
        tap: "tap-referral-saasquatch",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_responsys,
        displayName: "Responsys",
        tap: "tap-responsys",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_revinate,
        displayName: "Revinate",
        tap: "tap-revinate",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_sftp,
        displayName: "SFTP",
        tap: "tap-sftp",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_saasoptics,
        displayName: "SaaSOptics",
        tap: "tap-saasoptics",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_salesforce,
        displayName: "Salesforce",
        tap: "tap-salesforce",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_salesforce_marketing_cloud,
        displayName: "Salesforce Marketing Cloud",
        tap: "tap-exacttarget",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_selligent,
        displayName: "Selligent",
        tap: "tap-selligent",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_sendgrid_core,
        displayName: "SendGrid Core",
        tap: "tap-sendgrid",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_shiphero,
        displayName: "ShipHero",
        tap: "tap-shiphero",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_shippo,
        displayName: "Shippo",
        tap: "tap-shippo",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_shopify,
        displayName: "Shopify",
        tap: "tap-shopify",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-shopify", {
            customConfig: [
                {
                    displayName: "API Key",
                    id: "api_key",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Read more about", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://shopify.dev/tutorials/generate-api-credentials" }, { children: "How to obtain API Key" }))] })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    required: true,
                    type: types_1.dashDateType,
                    defaultValue: "2018-01-01",
                },
                {
                    displayName: "Shop",
                    id: "shop",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Name of your Shopify shop from URL: https://", "<YOUR_SHOP_NAME>", ".myshopify.com"] })),
                },
            ],
        }),
        documentation: documentation_1.shopifyDocumentation,
    },
    {
        deprecated: true,
        pic: logos.tap_slack,
        displayName: "Slack",
        tap: "tap-slack",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-slack", {
            customConfig: [
                {
                    displayName: "Access Token",
                    id: "token",
                    type: types_1.stringType,
                    required: true,
                    documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["You can obtain a token for a single workspace by creating a new", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://api.slack.com/apps?new_app=1" }, { children: "Slack App" })), " ", "in your workspace and assigning it the relevant", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://api.slack.com/docs/oauth-scopes" }, { children: "scopes" })), ". As of right now, the minimum required scopes for this App are: channels:history, channels:join, channels:read, files:read, groups:read, reactions:read, remote_files:read, team:read, usergroups:read, users.profile:read, users:read, users:read.email"] })),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    required: true,
                    type: types_1.isoUtcDateType,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                },
                {
                    displayName: "Exclude Archive Channels",
                    id: "exclude_archived",
                    type: types_1.booleanType,
                    defaultValue: false,
                },
                {
                    displayName: "Join Public Channels",
                    id: "join_public_channels",
                    type: types_1.booleanType,
                    defaultValue: false,
                },
                {
                    displayName: "Join Private Channels",
                    id: "private_channels",
                    type: types_1.booleanType,
                    defaultValue: false,
                },
            ],
        }),
        documentation: documentation_1.slackDocumentation,
    },
    // {
    //     pic: logos.tap_square,
    //     displayName: "Square",
    //     tap: "tap-square",
    //     stable: true,
    //     hasNativeEquivalent: false
    // },
    {
        deprecated: true,
        pic: logos.tap_stripe,
        displayName: "Stripe",
        tap: "tap-stripe",
        stable: true,
        hasNativeEquivalent: false,
        parameters: (0, helper_1.customParameters)("tap-stripe", {
            customConfig: [
                {
                    displayName: "Client Secret",
                    id: "client_secret",
                    type: types_1.stringType,
                    required: true,
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Client secret ('sk_live_....')" }),
                },
                {
                    displayName: "Account ID",
                    id: "account_id",
                    type: types_1.stringType,
                    required: true,
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Account ID ('acct_....')" }),
                },
                {
                    displayName: "Start Date",
                    id: "start_date",
                    type: types_1.isoUtcDateType,
                    required: true,
                    defaultValue: "2021-01-01T00:00:00.000Z",
                    documentation: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Jitsu will sync Stipe data since Start Date" }),
                },
            ],
        }),
        documentation: documentation_1.stripeDocumentation,
    },
    {
        deprecated: true,
        pic: logos.tap_surveymonkey,
        displayName: "SurveyMonkey",
        tap: "tap-surveymonkey",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_taboola,
        displayName: "Taboola",
        tap: "tap-taboola",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_toggl,
        displayName: "Toggl",
        tap: "tap-toggl",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_trello,
        displayName: "Trello",
        tap: "tap-trello",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_typeform,
        displayName: "Typeform",
        tap: "tap-typeform",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_urban_airship,
        displayName: "Urban Airship",
        tap: "tap-urban-airship",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_uservoice,
        displayName: "Uservoice",
        tap: "tap-uservoice",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_wootric,
        displayName: "Wootric",
        tap: "tap-wootric",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_workday_raas,
        displayName: "Workday RaaS",
        tap: "tap-workday-raas",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_xero,
        displayName: "Xero",
        tap: "tap-xero",
        // parameters: customParameters("tap-xero", {
        //   customConfig: [
        //     {
        //       displayName: "Client ID",
        //       id: "client_id",
        //       type: oauthSecretType,
        //       required: true
        //     },
        //     {
        //       displayName: "Client Secret",
        //       id: "client_secret",
        //       type: oauthSecretType,
        //       required: true
        //     },
        //     {
        //       displayName: "Refresh Token",
        //       id: "refresh_token",
        //       type: stringType,
        //       required: true
        //     },
        //     {
        //       displayName: "Tenant ID (ID of organization)",
        //       id: "tenant_id",
        //       type: stringType,
        //       required: true
        //     },
        //     {
        //       displayName: "Start Date",
        //       id: "start_date",
        //       type: isoUtcDateType,
        //       defaultValue: "2018-01-01T00:00:00.000Z",
        //       required: true,
        //     },
        //   ],
        // }),
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_yotpo,
        displayName: "Yotpo",
        tap: "tap-yotpo",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_zendesk_chat,
        displayName: "Zendesk Chat",
        tap: "tap-zendesk-chat",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_zendesk_support,
        displayName: "Zendesk Support",
        tap: "tap-zendesk",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_zoom,
        displayName: "Zoom",
        tap: "tap-zoom",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        deprecated: true,
        pic: logos.tap_zuora,
        displayName: "Zuora",
        tap: "tap-zuora",
        stable: true,
        hasNativeEquivalent: false,
    },
    {
        pic: logos.tap_ilevel,
        displayName: "iLEVEL",
        tap: "tap-ilevel",
        stable: true,
        hasNativeEquivalent: false,
    },
];
